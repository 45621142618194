export const timeZones = [
    {
      Abbreviation: "ET",
      Description: "(UTC-05:00) Eastern Time (US & Canada)",
      Central: "US/Eastern",
      LiveMeeting: "America/New York",
      Windows: "Eastern Standard Time",
    },
    {
      Abbreviation: "CT",
      Description: "(UTC-06:00) Central Time (US & Canada)",
      Central: "US/Central",
      LiveMeeting: "America/Chicago",
      Windows: "Central Standard Time",
    },
    {
      Abbreviation: "MT",
      Description: "(UTC-07:00) Mountain Time (US & Canada)",
      Central: "US/Mountain",
      LiveMeeting: "America/Denver ",
      Windows: "Mountain Standard Time",
    },
    {
      Abbreviation: "PT",
      Description: "(UTC-08:00) Pacific Time (US & Canada)",
      Central: "US/Pacific",
      LiveMeeting: "America/Los Angeles ",
      Windows: "Pacific Standard Time",
    },
    {
      Abbreviation: "AK",
      Description: "(UTC-09:00) Alaska",
      Central: "America/Anchorage",
      LiveMeeting: "America/Anchorage",
      Windows: "Alaskan Standard Time",
    },
    {
      Abbreviation: "HAST",
      Description: "(UTC-10:00) Hawaii",
      Central: "Pacific/Honolulu",
      LiveMeeting: "Pacific/Honolulu",
      Windows: "Hawaiian Standard Time",
    },
    {
      Abbreviation: "MST",
      Description: "(UTC-07:00) Arizona",
      Central: "US/Arizona",
      LiveMeeting: "America/Phoenix",
      Windows: "Mountain Standard Time",
    },
    {
      Abbreviation: "AST",
      Description: "(UTC-04:00) Atlantic Time (Canada)",
      Central: "Canada/Atlantic",
      LiveMeeting: "America/Aruba",
      Windows: "Atlantic Standard Time",
    },
    {
      Abbreviation: "MOST",
      Description: "(UTC) Casablanca",
      Central: "Africa/Casablanca",
      LiveMeeting: "Africa/Casablanca",
      Windows: "Morocco Standard Time",
    },
    {
      Abbreviation: "UTC",
      Description: "(UTC) Coordinated Universal Time",
      Central: "UTC",
      LiveMeeting: "Etc/GMT+0",
      Windows: "UTC",
    },
    {
      Abbreviation: "GMT",
      Description: "(UTC) Dublin, Edinburgh, Lisbon, London",
      Central: "Europe/London ",
      LiveMeeting: "Europe/London ",
      Windows: "GMT Standard Time",
    },
    {
      Abbreviation: "GST",
      Description: "(UTC) Monrovia, Reykjavik",
      Central: "Africa/Casablanca",
      LiveMeeting: "Africa/Casablanca",
      Windows: "Greenwich Standard Time",
    },
    {
      Abbreviation: "WET",
      Description: "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
      Central: "Europe/Amsterdam ",
      LiveMeeting: "Europe/Amsterdam ",
      Windows: "West Europe Standard Time",
    },
    {
      Abbreviation: "CET",
      Description: "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
      Central: "Europe/Belgrade ",
      LiveMeeting: "Europe/Belgrade ",
      Windows: "Central Europe Standard Time",
    },
    {
      Abbreviation: "RST",
      Description: "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
      Central: "Europe/Brussels",
      LiveMeeting: "Europe/Copenhagen",
      Windows: "Romance Standard Time",
    },
    {
      Abbreviation: "CEST",
      Description: "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
      Central: "Europe/Sarajevo",
      LiveMeeting: "Europe/Sarajevo",
      Windows: "Central European Standard Time",
    },
    {
      Abbreviation: "ECT",
      Description: "(UTC+01:00) West Central Africa",
      Central: "Africa/Brazzaville",
      LiveMeeting: "Africa/Douala",
      Windows: "W. Central Africa Standard Time",
    },
    {
      Abbreviation: "JST",
      Description: "(UTC+02:00) Amman",
      Central: "Europe/Athens",
      LiveMeeting: "Europe/Bucharest",
      Windows: "Jordan Standard Time",
    },
    {
      Abbreviation: "GTBST ",
      Description: "(UTC+02:00) Athens, Bucharest, Istanbul",
      Central: "Europe/Athens",
      LiveMeeting: "Europe/Bucharest",
      Windows: "GTB Standard Time",
    },
    {
      Abbreviation: "MEST",
      Description: "MEST (UTC+02:00) Beirut",
      Central: "Africa/Cairo",
      LiveMeeting: "Africa/Cairo",
      Windows: "Middle East Standard Time",
    },
    {
      Abbreviation: "EGST",
      Description: "EGST (UTC+02:00) Cairo",
      Central: "Africa/Cairo",
      LiveMeeting: "Africa/Cairo",
      Windows: "Egypt Standard Time",
    },
    {
      Abbreviation: "SST",
      Description: "(UTC+02:00) Damascus",
      Central: "Africa/Cairo",
      LiveMeeting: "Africa/Cairo",
      Windows: "Syria Standard Time",
    },
    {
      Abbreviation: "SAST",
      Description: "(UTC+02:00) Harare, Pretoria",
      Central: "Africa/Harare",
      LiveMeeting: "Africa/Harare",
      Windows: "South Africa Standard Time",
    },
    {
      Abbreviation: "EET",
      Description: "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
      Central: "Europe/Helsinki",
      LiveMeeting: "Europe/Helsinki",
      Windows: "FLE Standard Time",
    },
    {
      Abbreviation: "ISST",
      Description: "(UTC+02:00) Jerusalem",
      Central: "Asia/Jerusalem",
      LiveMeeting: "Asia/Jerusalem",
      Windows: "Israel Standard Time",
    },
    {
      Abbreviation: "EEST",
      Description: "(UTC+02:00) Minsk",
      Central: "Asia/Jerusalem",
      LiveMeeting: "Asia/Jerusalem",
      Windows: "E. Europe Standard Time",
    },
    {
      Abbreviation: "NMST",
      Description: "(UTC+02:00) Windhoek",
      Central: "Asia/Jerusalem",
      LiveMeeting: "Asia/Jerusalem",
      Windows: "Namibia Standard Time",
    },
    {
      Abbreviation: "ARST",
      Description: "(UTC+03:00) Baghdad",
      Central: "Asia/Baghdad",
      LiveMeeting: "Asia/Baghdad",
      Windows: "Arabic Standard Time",
    },
    {
      Abbreviation: "ABST",
      Description: "(UTC+03:00) Kuwait, Riyadh",
      Central: "Asia/Kuwait",
      LiveMeeting: "Asia/Kuwait",
      Windows: "Arab Standard Time",
    },
    {
      Abbreviation: "MSK",
      Description: "(UTC+03:00) Moscow, St. Petersburg, Volgograd",
      Central: "Europe/Moscow",
      LiveMeeting: "Europe/Moscow",
      Windows: "Russian Standard Time",
    },
    {
      Abbreviation: "EAT",
      Description: "(UTC+03:00) Nairobi",
      Central: "Asia/Kuwait ",
      LiveMeeting: "Asia/Kuwait ",
      Windows: "E. Africa Standard Time",
    },
    {
      Abbreviation: "IRST",
      Description: "(UTC+03:30) Tehran",
      Central: "Asia/Tehran",
      LiveMeeting: "Asia/Tehran",
      Windows: "Iran Standard Time",
    },
    {
      Abbreviation: "ARBST",
      Description: "(UTC+04:00) Abu Dhabi, Muscat",
      Central: "Asia/Muscat",
      LiveMeeting: "Asia/Muscat",
      Windows: "Arabian Standard Time",
    },
    {
      Abbreviation: "AZT",
      Description: "(UTC+04:00) Baku",
      Central: "Asia/Baku",
      LiveMeeting: "Asia/Baku",
      Windows: "Azerbaijan Standard Time",
    },
    {
      Abbreviation: "MUT",
      Description: "(UTC+04:00) Port Louis",
      Central: "Asia/Baku",
      LiveMeeting: "Asia/Baku",
      Windows: "Mauritius Standard Time",
    },
    {
      Abbreviation: "GET",
      Description: "(UTC+04:00) Tbilisi",
      Central: "Asia/Baku",
      LiveMeeting: "Asia/Baku",
      Windows: "Georgian Standard Time",
    },
    {
      Abbreviation: "AMT",
      Description: "(UTC+04:00) Yerevan",
      Central: "Asia/Baku",
      LiveMeeting: "Asia/Baku",
      Windows: "Caucasus Standard Time",
    },
    {
      Abbreviation: "AFT",
      Description: "(UTC+04:30) Kabul",
      Central: "Asia/Baku",
      LiveMeeting: "Asia/Baku",
      Windows: "Afghanistan Standard Time",
    },
    {
      Abbreviation: "YEKT",
      Description: "(UTC+05:00) Ekaterinburg",
      Central: "Asia/Tashkent ",
      LiveMeeting: "Asia/Yekaterinburg",
      Windows: "Ekaterinburg Standard Time",
    },
    {
      Abbreviation: "PKT",
      Description: "(UTC+05:00) Islamabad, Karachi",
      Central: "Asia/Tashkent",
      LiveMeeting: "Asia/Karachi",
      Windows: "Pakistan Standard Time",
    },
    {
      Abbreviation: "WAST",
      Description: "(UTC+05:00) Tashkent",
      Central: "Asia/Tashkent",
      LiveMeeting: "Asia/Yekaterinburg",
      Windows: "West Asia Standard Time",
    },
    {
      Abbreviation: "IST",
      Description: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
      Central: "Asia/Calcutta",
      LiveMeeting: "Asia/Calcutta",
      Windows: "India Standard Time",
    },
    {
      Abbreviation: "SLT",
      Description: "(UTC+05:30) Sri Jayawardenepura",
      Central: "Asia/Calcutta",
      LiveMeeting: "Asia/Calcutta",
      Windows: "Sri Lanka Standard Time",
    },
    {
      Abbreviation: "NPT",
      Description: "(UTC+05:45) Kathmandu",
      Central: "Asia/Katmandu",
      LiveMeeting: "Asia/Katmandu",
      Windows: "Nepal Standard Time",
    },
    {
      Abbreviation: "BTT",
      Description: "(UTC+06:00) Astana",
      Central: "Asia/Dhaka",
      LiveMeeting: "Asia/Dhaka",
      Windows: "Central Asia Standard Time",
    },
    {
      Abbreviation: "BST",
      Description: "(UTC+06:00) Dhaka",
      Central: "Asia/Dhaka",
      LiveMeeting: "Asia/Dhaka",
      Windows: "Bangladesh Standard Time",
    },
    {
      Abbreviation: "NCAST",
      Description: "(UTC+06:00) Novosibirsk",
      Central: "Asia/Almaty",
      LiveMeeting: "Asia/Dhaka",
      Windows: "N. Central Asia Standard Time",
    },
    {
      Abbreviation: "MYST",
      Description: "(UTC+06:30) Yangon (Rangoon)",
      Central: "Asia/Rangoon",
      LiveMeeting: "Asia/Rangoon",
      Windows: "Myanmar Standard Time",
    },
    {
      Abbreviation: "THA",
      Description: "(UTC+07:00) Bangkok, Hanoi, Jakarta",
      Central: "Asia/Bangkok",
      LiveMeeting: "Asia/Bangkok",
      Windows: "SE Asia Standard Time",
    },
    {
      Abbreviation: "KRAT",
      Description: "(UTC+07:00) Krasnoyarsk",
      Central: "Asia/Bangkok",
      LiveMeeting: "Asia/Bangkok",
      Windows: "North Asia Standard Time",
    },
    {
      Description: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
      Central: "Asia/Hong Kong",
      LiveMeeting: "Asia/Hong Kong",
      Windows: "China Standard Time",
    },
    {
      Abbreviation: "IRKT",
      Description: "(UTC+08:00) Irkutsk",
      Central: "Asia/Irkutsk",
      LiveMeeting: "Asia/Irkutsk",
      Windows: "North Asia East Standard Time",
    },
    {
      Abbreviation: "SNST",
      Description: "(UTC+08:00) Kuala Lumpur, Singapore",
      Central: "Asia/Singapore",
      LiveMeeting: "Asia/Taipei",
      Windows: "Singapore Standard Time",
    },
    {
      Abbreviation: "AWST",
      Description: "(UTC+08:00) Perth",
      Central: "Australia/Perth",
      LiveMeeting: "Australia/Perth",
      Windows: "W. Australia Standard Time",
    },
    {
      Abbreviation: "TIST",
      Description: "(UTC+08:00) Taipei",
      Central: "Asia/Taipei ",
      LiveMeeting: "Asia/Taipei ",
      Windows: "Taipei Standard Time",
    },
    {
      Abbreviation: "UST",
      Description: "(UTC+08:00) Ulaanbaatar",
      Central: "Asia/Taipei",
      LiveMeeting: "Asia/Taipei",
      Windows: "Ulaanbaatar Standard Time",
    },
    {
      Abbreviation: "TST",
      Description: "(UTC+09:00) Osaka, Sapporo, Tokyo",
      Central: "Asia/Tokyo",
      LiveMeeting: "Asia/Tokyo",
      Windows: "Tokyo Standard Time",
    },
    {
      Abbreviation: "KST",
      Description: "(UTC+09:00) Seoul",
      Central: "Asia/Seoul",
      LiveMeeting: "Asia/Seoul",
      Windows: "Korea Standard Time",
    },
    {
      Abbreviation: "YAKT",
      Description: "(UTC+09:00) Yakutsk",
      Central: "Asia/Yakutsk",
      LiveMeeting: "Asia/Yakutsk",
      Windows: "Yakutsk Standard Time",
    },
    {
      Abbreviation: "CAUST",
      Description: "(UTC+09:30) Adelaide",
      Central: "Australia/Adelaide ",
      LiveMeeting: "Australia/Adelaide ",
      Windows: "Cen. Australia Standard Time",
    },
    {
      Abbreviation: "ACST",
      Description: "(UTC+09:30) Darwin",
      Central: "Australia/Darwin",
      LiveMeeting: "Australia/Darwin",
      Windows: "AUS Central Standard Time",
    },
    {
      Abbreviation: "EAST",
      Description: "(UTC+10:00) Brisbane",
      Central: "Australia/Brisbane",
      LiveMeeting: "Australia/Brisbane",
      Windows: "E. Australia Standard Time",
    },
    {
      Abbreviation: "AEST",
      Description: "(UTC+10:00) Canberra, Melbourne, Sydney",
      Central: "Australia/Sydney",
      LiveMeeting: "Australia/Sydney",
      Windows: "AUS Eastern Standard Time",
    },
    {
      Abbreviation: "WPST",
      Description: "(UTC+10:00) Guam, Port Moresby",
      Central: "Pacific/Guam",
      LiveMeeting: "Pacific/Guam",
      Windows: "West Pacific Standard Time",
    },
    {
      Abbreviation: "TAST",
      Description: "(UTC+10:00) Hobart",
      Central: "Australia/Hobart",
      LiveMeeting: "Australia/Hobart",
      Windows: "Tasmania Standard Time",
    },
    {
      Abbreviation: "VLAT",
      Description: "(UTC+10:00) Vladivostok",
      Central: "Asia/Vladivostok",
      LiveMeeting: "Asia/Vladivostok",
      Windows: "Vladivostok Standard Time",
    },
    {
      Abbreviation: "SBT",
      Description: "(UTC+11:00) Magadan, Solomon Is., New Caledonia",
      Central: "Pacific/Guadalcanal",
      LiveMeeting: "Pacific/Guadalcanal",
      Windows: "Central Pacific Standard Time",
    },
    {
      Abbreviation: "NZST",
      Description: "(UTC+12:00) Auckland, Wellington",
      Central: "Pacific/Auckland",
      LiveMeeting: "Pacific/Auckland",
      Windows: "New Zealand Standard Time",
    },
    {
      Abbreviation: "UTC12",
      Description: "(UTC+12:00) Coordinated Universal Time+12",
      Central: "Etc/GMT-12",
      LiveMeeting: "Etc/GMT-12",
      Windows: "UTC+12",
    },
    {
      Abbreviation: "FJT",
      Description: "(UTC+12:00) Fiji",
      Central: "Pacific/Fiji",
      LiveMeeting: "Pacific/Fiji",
      Windows: "Fiji Standard Time",
    },
    {
      Abbreviation: "PETT",
      Description: "(UTC+12:00) Petropavlovsk-Kamchatsky - Old",
      Central: "Asia/Kamchatka",
      LiveMeeting: "Etc/GMT+12",
      Windows: "Kamchatka Standard Time",
    },
    {
      Abbreviation: "PHOT",
      Description: "(UTC+13:00) Nuku'alofa",
      Central: "Pacific/Tongatapu",
      LiveMeeting: "Pacific/Tongatapu",
      Windows: "Tonga Standard Time",
    },
    {
      Abbreviation: "AZOST",
      Description: "(UTC-01:00) Azores",
      Central: "Atlantic/Azores",
      LiveMeeting: "Atlantic/Azores",
      Windows: "Azores Standard Time",
    },
    {
      Abbreviation: "CVT",
      Description: "(UTC-01:00) Cape Verde Is.",
      Central: "Atlantic/Cape Verde",
      LiveMeeting: "Atlantic/Cape Verde",
      Windows: "Cape Verde Standard Time",
    },
    {
      Abbreviation: "ESAST",
      Description: "(UTC-03:00) Brasilia America/Sao_Paulo",
      Central: "America/Sao_Paulo",
      LiveMeeting: "America/Sao_Paulo",
      Windows: "E. South America Standard Time",
    },
    {
      Abbreviation: "ART",
      Description: "(UTC-03:00) Buenos Aires",
      Central: "America/Buenos Aires",
      LiveMeeting: "America/Buenos Aires",
      Windows: "Argentina Standard Time",
    },
    {
      Abbreviation: "SAEST",
      Description: "(UTC-03:00) Cayenne, Fortaleza",
      Central: "SA Eastern Standard Time",
      LiveMeeting: "SA Eastern Standard Time",
      Windows: "SA Eastern Standard Time",
    },
    {
      Abbreviation: "GNST",
      Description: "(UTC-03:00) Greenland",
      Central: "America/Godthab",
      LiveMeeting: "America/Godthab",
      Windows: "Greenland Standard Time",
    },
    {
      Abbreviation: "MVST",
      Description: "(UTC-03:00) Montevideo",
      Central: "America/Godthab",
      LiveMeeting: "America/Montevideo",
      Windows: "Montevideo Standard Time",
    },
    {
      Abbreviation: "NST",
      Description: "(UTC-03:30) Newfoundland",
      Central: "Canada/Newfoundland",
      LiveMeeting: "Canada/Newfoundland",
      Windows: "Newfoundland Standard Time",
    },
    {
      Abbreviation: "PRST",
      Description: "(UTC-04:00) Asuncion",
      Central: "Canada/Atlantic ",
      LiveMeeting: "America/Aruba",
      Windows: "Paraguay Standard Time",
    },
    {
      Abbreviation: "CBST",
      Description: "(UTC-04:00) Cuiaba",
      Central: "Canada/Atlantic",
      LiveMeeting: "America/Aruba",
      Windows: "Central Brazilian Standard Time",
    },
    {
      Abbreviation: "SAWST",
      Description: "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
      Central: "America/Santiago",
      LiveMeeting: "America/Santiago",
      Windows: "SA Western Standard Time",
    },
    {
      Abbreviation: "PSAST",
      Description: "(UTC-04:00) Santiago",
      Central: "America/Santiago",
      LiveMeeting: "America/Santiago",
      Windows: "Pacific SA Standard Time",
    },
    {
      Abbreviation: "VST",
      Description: "(UTC-04:30) Caracas",
      Central: "America/Caracas",
      LiveMeeting: "America/Caracas",
      Windows: "Venezuela Standard Time",
    },
    {
      Abbreviation: "SAPST",
      Description: "(UTC-05:00) Bogota, Lima, Quito",
      Central: "America/Bogota",
      LiveMeeting: "America/Bogota",
      Windows: "SA Pacific Standard Time",
    },
    {
      Abbreviation: "EST",
      Description: "(UTC-05:00) Indiana (East)",
      Central: "US/East-Indiana",
      LiveMeeting: "America/Halifax",
      Windows: "US Eastern Standard Time",
    },
    {
      Abbreviation: "CAST",
      Description: "(UTC-06:00) Central America",
      Central: "America/El_Salvador",
      LiveMeeting: "America/Mexico_City",
      Windows: "Central America Standard Time",
    },
    {
      Abbreviation: "CST",
      Description: "(UTC-06:00) Guadalajara, Mexico City, Monterrey",
      Central: "America/Mexico_City",
      LiveMeeting: "America/Mexico_City",
      Windows: "Central Standard Time (Mexico)",
    },
    {
      Abbreviation: "CCST",
      Description: "(UTC-06:00) Saskatchewan",
      Central: "Canada/Saskatchewan",
      LiveMeeting: "Canada/Saskatchewan",
      Windows: "Canada Central Standard Time",
    },
    {
      Abbreviation: "MSTM",
      Description: "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
      Central: "America/Chihuahua",
      LiveMeeting: "America/Mazatlan",
      Windows: "Mountain Standard Time (Mexico)",
    },
    {
      Abbreviation: "PST",
      Description: "(UTC-08:00) Baja California",
      Central: "US/Pacific",
      LiveMeeting: "America/Los Angeles",
      Windows: "Pacific Standard Time (Mexico)",
    },
    {
      Abbreviation: "SMST",
      Description: "(UTC-11:00) Samoa",
      Central: "Pacific/Midway",
      LiveMeeting: "Pacific/Midway",
      Windows: "Samoa Standard Time",
    },
    {
      Abbreviation: "BIT",
      Description: "(UTC-12:00) International Date Line West",
      Central: "Etc/GMT+12",
      LiveMeeting: "Etc/GMT+12",
      Windows: "Dateline Standard Time",
    },
  ]